<template>
  <section class="notFound">
    <div class="img">
      <img
        src="@/assets/images/errors/back_to_home.png"
        :alt="$t('errors.back_to_home')"
      />
      <img
        src="@/assets/images/errors/delorean.png"
        alt="Delorean, Doc e Marti McFly"
      />
    </div>
    <div class="text">
      <h1>404</h1>
      <h2>{{ $t("errors.404") }}</h2>
      <h3>{{ $t("errors.back_to_home").toUpperCase() }}?</h3>
      <router-link :to="{ name: 'home' }" class="yes">{{
        $t("yes").toUpperCase()
      }}</router-link>
      <a href="#">{{ $t("no").toUpperCase() }}</a>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped lang="scss">
@import "../assets/css/error.scss";
</style>
